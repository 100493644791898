<template>
  <div class="border-0">
    <div class="mt-7 pb-0">
      <div>
        <div class="d-flex flex-column text-center totalRecords-row">
          <div class="totalRecords-titleHolder">
            <span style="display: inline-flex; align-items: center">
              <span class="totalRecords-Title">Total Records</span>
              <v-icon
                id="totalRecords-help-icon"
                small
                color="#2C9A65"
                style="left: 6px"
                class="review-Helpbutton-Container"
                >mdi-information-outline</v-icon
              >
            </span>
            <b-tooltip target="totalRecords-help-icon" placement="bottom">
              The total number of individual records supplied
            </b-tooltip>
          </div>

          <animate-number
            style="
              font-size: 24px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33;
              color: #2c9a65;
            "
            from="0"
            duration="1000"
            :to="totalRecords()"
          >
          </animate-number>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "totalRecords",
  props: {
    currentTask: Object,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {},
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat("en-GB", { dateStyle: "full" }).format(
        date
      );
    },
    totalRecords() {
      var retVal = "";
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = currentTaskResults.noOfRecords;
      return retVal;
    },
  },
};
</script>

<style>
.totalRecords-Title {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #62657d;
  text-align: center;
  white-space: nowrap;
}

.totalRecords-titleHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.totalRecords-titleHolder span {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.totalRecords-Count {
  font-family: "Poppins";
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #2c9a65;
}

.totalRecords-row {
  width: 100%;
}
</style>
